<template>
  <a-card class="card" :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="GLOBAL.queryRowGutter">
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="录单时间">
              <a-range-picker @change="onDateChange" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="计划号">
              <a-input v-model="queryParam.plan_id" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="业务来源">
              <a-select
                showSearch
                allowClear
                placeholder="请选择业务来源"
                style="width: 100%"
                v-model="queryParam.source"
              >
                <a-select-option v-for="item in sourceOps" :key="item.value">{{ item.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="报关单位">
              <a-select
                showSearch
                label-in-value
                allowClear
                placeholder="请选择报关单位"
                style="width: 100%"
                :value="customerInfo"
                :filter-option="false"
                :not-found-content="fetching ? undefined : null"
                @search="getDepartmentCustomer"
                @change="changeCustomerInfo"
              >
                <template v-if="fetching" #notFoundContent>
                  <a-spin size="small" />
                </template>
                <a-select-option v-for="op in customerOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="开票时间">
              <a-range-picker @change="onInvoiceDateChange"/>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="发票号">
              <a-input v-model="queryParam.invoice_num" class="full-width" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="费用状态">
              <a-select
                v-model="queryParam['fee_status']"
                showSearch
                allowClear
                :filter-option="filterOption"
                style="width: 100%"
              >
                <a-select-option v-for="o in this.GLOBAL.finFeeStatusMaps" :key="o.value">{{ o.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-operator">
      <a-button type="query" icon="search" @click="$refs.table.refresh(true)">查询</a-button>
      <a-button type="primary" :disabled="!selectedRowKeys.length" @click="handleVerifyOrAudit('audit')">审核</a-button>
      <a-button type="primary" :disabled="!selectedRowKeys.length" @click="handleVerifyOrAudit('undo_audit')">反审核</a-button>
      <a-button type="primary" :disabled="!selectedRowKeys.length" @click="handleBillApply()">开票申请</a-button>
      <a-popover v-model="popVisible" title="选择月份" trigger="click">
        <a slot="content">
          <p><a-month-picker v-model="exportDate" style="width: 200px" format="YYYY/MM" placeholder="请选择月份" /></p>
          <p style="text-align: right"><a-button type="primary" @click="handleGenerateBill">确定</a-button></p>
        </a>
        <a-button type="primary" :disabled="!selectedRowKeys.length">月结对账单</a-button>
      </a-popover>
      <!-- <a-button type="primary" @click="handleExport()">导出</a-button> -->
      <a-button type="primary" @click="handleCmsSourseExport('year')">查验业务年报</a-button>
      <a-button type="primary" @click="handleCmsSourseExport('month')">查验业务月报</a-button>
      <a-button type="primary" @click="handleCmsSourseExport('day')">查验业务日报</a-button>
      <a-popover
        v-model="exportPopVisible"
        title="选择日期"
        trigger="click">
        <a slot="content">
          <p><a-date-picker style="width: 200px" @change="onDateSingleChange" /></p>
          <p style="text-align: right"><a-button type="primary" @click="wmsPosInvoiceSettlementSheet">确定</a-button></p>
        </a>
        <a-button type="primary" icon="download">POS机发票结款清单</a-button>
      </a-popover>
      <a-popover
        v-model="exportPopVisible2"
        title="选择日期"
        trigger="click">
        <a slot="content">
          <p><a-date-picker style="width: 200px" @change="onDateSingleChange" /></p>
          <p style="text-align: right"><a-button type="primary" @click="payIncomeTotalSheet">确定</a-button></p>
        </a>
        <a-button type="primary" icon="download">通联收入汇总</a-button>
      </a-popover>
      <a-popover
        v-model="exportPopVisible3"
        title="选择日期"
        trigger="click">
        <a slot="content">
          <p><a-range-picker style="width: 200px" @change="onDateRangeChange" /></p>
          <p style="text-align: right"><a-button type="primary" @click="wmsChargeTotalSheet">确定</a-button></p>
        </a>
        <a-button type="primary" icon="download">仓储现金收费清单</a-button>
      </a-popover>
    </div>
    <s-table
      ref="table"
      size="default"
      rowKey="id"
      :alert="{
        show: true,
        msg: showTotal
      }"
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data="loadData"
      :rowSelection="{
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange,
        onSelect: this.selectItem,
        onSelectAll: this.selectAllItem
      }"
    >
      <div slot="feeItem" slot-scope="fee">
        <div v-for="item in fee" :key="item.id">
          <a-tag :color="feeStatusColor(item.fee_status)" style="margin-bottom:2px;">
            {{ GLOBAL.feeStatusMaps[item.fee_status] ? GLOBAL.feeStatusMaps[item.fee_status].name : '' }}
          </a-tag>
          <span v-if="item.charge_type === 1">
            {{ item.fee_name }}
            <a-tag color="#DADADA" style="margin-bottom:2px;">
              现金：{{ item.amount }}
            </a-tag>
          </span>
          <span v-else-if="item.charge_type === 2">
            {{ item.fee_name }}
            <a-tag color="#A940FF" style="margin-bottom:2px;">
              月结：{{ item.amount }}
            </a-tag>
          </span>
        </div>
      </div>
    </s-table>

    <a-modal
      :visible="applyFormVisible"
      :confirmLoading="confirmLoading"
      title="开票申请"
      :width="720"
      @cancel="applyFormVisible = false"
      @ok="handleApplyFormSubmit"
    >
      <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
        <a-row :gutter="20">
          <a-divider orientation="left">订单信息</a-divider>
          <a-col :span="8">
            <a-form-model-item label="委托单位">
              <a-input v-model="bizAuditForm.customer" :disabled="true" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="所属部门">
              <a-input v-model="bizAuditForm.department" :disabled="true" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="录入人">
              <a-input v-model="bizAuditForm.creator" :disabled="true" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="申请时间">
              <a-input v-model="bizAuditForm.applyTime" :disabled="true" />
            </a-form-model-item>
          </a-col>
          <a-divider orientation="left">费用信息
            <a-tag color="#f50" style="margin-left:10px;">合计:{{ totalAmount }}</a-tag>
          </a-divider>
          <a-checkbox
            :indeterminate="indeterminate"
            :checked="checkAll"
            @change="onCheckAllChange"
            style="padding-left:10px;padding-bottom:10px;"
          >
            全选
          </a-checkbox>
          <a-col :span="24">
            <a-checkbox-group v-model="checkedList" @change="feeOnChange" style="width:100%;">
              <a-row v-for="item in bizAuditForm.fees" :key="item.id">
                <a-alert :message="`业务编号:${item.planId}`" type="info" show-icon style="margin-bottom:10px;" />
                <a-col
                  :span="8"
                  v-for="fee in item.feeList"
                  :key="fee.id"
                  style="padding-left:15px;padding-bottom:10px;"
                >
                  <a-checkbox :value="fee.id"> {{ fee.feeName }}:{{ fee.money }} </a-checkbox>
                </a-col>
              </a-row>
            </a-checkbox-group>
          </a-col>
          <a-col :span="24">
            <a-form-model-item label="申请备注">
              <a-textarea v-model="form.apply_remark"></a-textarea>
            </a-form-model-item>
          </a-col>
          <a-divider orientation="left">开票信息</a-divider>
          <a-col :span="8">
            <a-form-model-item label="委托单位/客户名称" prop="customer_name">
              <a-select
                showSearch
                label-in-value
                allowClear
                placeholder="请选择委托客户"
                style="width: 100%"
                :value="customerInfo2"
                :filter-option="false"
                :not-found-content="fetching2 ? undefined : null"
                @search="getDepartmentCustomer2"
                @change="changeCustomerInfo2"
              >
                <template v-if="fetching2" #notFoundContent>
                  <a-spin size="small" />
                </template>
                <a-select-option v-for="op in buyerOps2" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="纳税人识别号" prop="tax_no">
              <a-input v-model="form.tax_no" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="地址">
              <a-input v-model="form.address" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="电话">
              <a-input v-model="form.mobile" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="开户银行">
              <a-input v-model="form.bank" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="银行卡号">
              <a-input v-model="form.bank_card_no" />
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item label="税率" prop="tax_rate">
              <a-select v-model="form.tax_rate">
                <a-select-option :value="item.value" v-for="item in taxRateOps" :key="item.value">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>
  </a-card>
</template>

<script>
import { STable } from '@/components'
import moment from 'moment'
import { filterOption } from '@/utils/util'
import {
getCmsMetaOption,
getCmsCharge,
// ,
// exportCmsChargeReport,
// exportCmsSourseReport
exportCmsSource
} from '@/api/cms'
import { wmsFinanceChargeFunc, generateWmsBill } from '@/api/wms'
import { getchargeTax, exportWmsPosInvoiceSettlementSheet, exportPayIncomeTotalSheet, exportChargeTotalSheet } from '@/api/fms'
import {
downloadExcel,
getCustomer,
getCustomerInfo
} from '@/api/common'
import debounce from 'lodash/debounce';
export default {
  components: {
    STable
  },
  data() {
    this.lastFetchId = 0;
    this.lastFetchId2 = 0
    this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 800);
    this.getDepartmentCustomer2 = debounce(this.getDepartmentCustomer2, 800)
    return {
      customerInfo: undefined,
      customerOps: [],
      buyerOps2: [],
      fetching: false,
      customerInfo2: undefined,
      fetching2: false,
      totalAmount: 0,
      checkedList: [],
      indeterminate: false,
      checkAll: true,
      queryParam: {},
      confirmLoading: false,
      chargeType: {
        1: { value: 1, name: '现金' },
        2: { value: 2, name: '月结' },
        3: { value: 3, name: '现金,月结' }
      },
      sourceOps: [],
      taxRateOps: [],
      showTotal: '',
      columns: [
        {
          title: '业务编号',
          dataIndex: 'serial_num'
        },
        {
          title: '录单时间',
          dataIndex: 'receive_time',
          customRender: (item) => {
            return moment(item).format('YYYY-MM-DD');
          }
        },
        {
          title: '计划号',
          dataIndex: 'plan_id'
        },
        {
          title: '报关单号',
          dataIndex: 'report_num'
        },
        {
          title: '报关单位',
          dataIndex: 'customs_name'
        },
        {
          title: '经营单位',
          dataIndex: 'operator_name'
        },
        {
          title: '品名',
          dataIndex: 'violation_name'
        },
        {
          title: '用车数量',
          dataIndex: 'vehicle_num'
        },
        {
          title: '查验费用',
          dataIndex: 'cms_fee_list',
          scopedSlots: { customRender: 'feeItem' }
        },
        {
          title: '仓储费用',
          dataIndex: 'wms_fee_list',
          scopedSlots: { customRender: 'feeItem' }
        },
        {
          title: '合计',
          dataIndex: 'total'
        }
      ],
      loadData: parameter => {
        return getCmsCharge(Object.assign(parameter, this.queryParam)).then(res => {
          this.showTotal = '票数合计:' + res.total + ';金额合计:' + res.total_row;
          return res
        })
      },
      selectedRowKeys: [],
      selectedRows: [],
      applyFormVisible: false,
      form: {
        apply_remark: '',
        customer_name: '',
        tax_no: '',
        address: '',
        mobile: '',
        bank: '',
        bank_card_no: '',
        remark: '',
        tax_rate: undefined
      },
      bizAuditForm: {
        customer: '',
        department: '查验部',
        creator: '',
        applyTime: moment().format('L'),
        fees: null,
        total: null
      },
      rules: {
        customer_name: [{ required: true, message: '请输入委托单位/客户名称', trigger: 'blur' }],
        tax_no: [{ required: true, message: '请输入纳税人识别号', trigger: 'blur' }],
        tax_rate: [{ required: true, message: '请选择税率', trigger: 'change' }]
      },
      bankAccountOps: [],
      popVisible: false,
      exportDate: undefined,
      exportPopVisible: false,
      exportPopVisible2: false,
      exportPopVisible3: false,
      exportStartDate: null,
      exportEndDate: null
    }
  },
  created() {
    getCmsMetaOption('source').then(v => {
      this.sourceOps = v
    })
    getchargeTax().then(v => {
      this.taxRateOps = v
    })
  },
  methods: {
    filterOption,
    getDepartmentCustomer2(value) {
      this.lastFetchId2 += 1
      const fetchId2 = this.lastFetchId2
      this.buyerOps2 = []
      this.fetching2 = true
      getCustomer({
        department: 1,
        customerName: value
      }).then(res => {
        if (fetchId2 !== this.lastFetchId2) {
          return
        }
        this.buyerOps2 = res
        this.fetching2 = false
      })
    },
    changeCustomerInfo2(value) {
      this.customerInfo2 = value
      this.form.customer_name = value !== undefined ? value.label : null
      this.buyerOps2.forEach(item => {
        if (item.value === value.key) {
          this.form.customer_id = value.key
          this.form.tax_no = item.taxNum
          this.form.address = item.street
          this.form.mobile = item.phone
          this.form.bank_card_no = item.account
          this.form.bank = item.bankName
        }
      })
      this.buyerOps2 = []
      this.fetching2 = false
    },
    getDepartmentCustomer(value) {
      this.lastFetchId += 1;
      const fetchId = this.lastFetchId;
      this.customerOps = [];
      this.fetching = true;
      getCustomer({
        department: 1,
        customerName: value
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return;
        }
        this.customerOps = res
        this.fetching = false;
      })
    },
    changeCustomerInfo(value) {
      if (value === undefined) {
        // 委托客户选择框中无值
        this.customerInfo = undefined;
        this.queryParam['auth_agency'] = null;
      } else {
        // 委托客户选择框中有值
        this.customerInfo = value;
        this.queryParam['auth_agency'] = value.key;
      }
      this.customerOps = []
      this.fetching = false
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
    },
    selectItem(record, selected, selectedRows) {
      if (selected) {
        let flag = true
        for (let index = 0; index < this.selectedRows.length; index++) {
          if (this.selectedRows[index].id === record.id) {
            flag = false
          }
        }
        if (flag) {
          this.selectedRows.push(record)
        }
      } else {
        for (let index = 0; index < this.selectedRows.length; index++) {
          if (this.selectedRows[index].id === record.id) {
            this.selectedRows.splice(index, 1)
          }
        }
      }
    },
    selectAllItem(selected, selectedRows, changeRows) {
      if (selected) {
        changeRows.forEach(data => {
          let flag = true
          this.selectedRows.forEach(item => {
            if (data.id === item.id) {
              flag = false
            }
          })
          if (flag) {
            this.selectedRows.push(data)
          }
        })
      } else {
        for (const item of changeRows) {
          for (let index = 0; index < this.selectedRows.length; index++) {
            if (item.id === this.selectedRows[index].id) {
              this.selectedRows.splice(index, 1)
            }
          }
        }
      }
    },
    onDateChange(date, dateString) {
      if (date[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss');
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss');
        this.queryParam['receive_start_date'] = startDate;
        this.queryParam['receive_end_date'] = endDate;
      } else {
        this.queryParam['receive_start_date'] = null;
        this.queryParam['receive_end_date'] = null;
      }
    },
    onInvoiceDateChange(date) {
      if (date[0]) {
        this.queryParam['invoice_start_time'] = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['invoice_end_time'] = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.queryParam['invoice_start_time'] = null
        this.queryParam['invoice_end_time'] = null
      }
    },
    handleVerifyOrAudit(operate) {
      // 审核
      const data = []
      this.selectedRows.forEach(v => {
        v.cms_fee_list.forEach(fee => {
          switch (operate) {
            case 'audit':
              if (fee.fee_status === 1 && fee.charge_type === 2) {
                data.push(fee.id)
              }
              break
            case 'undo_audit':
              if (fee.fee_status === 2 && fee.charge_type === 2) {
                data.push(fee.id)
              }
              break
          }
        })
        v.wms_fee_list.forEach(fee => {
          switch (operate) {
            case 'audit':
              if (fee.fee_status === 1 && fee.charge_type === 2) {
                data.push(fee.id)
              }
              break
            case 'undo_audit':
              if (fee.fee_status === 2 && fee.charge_type === 2) {
                data.push(fee.id)
              }
              break
          }
        })
      })
      if (data.length) {
        wmsFinanceChargeFunc(operate, data).then(_ => {
          this.$notification['success']({
            message: '提示',
            description: '操作成功'
          })
          this.$refs.table.clearSelected()
          this.selectedRows = []
          this.$refs.table.refresh(true)
        })
      } else {
        this.$message.warning('请选择' + (operate === 'undo_audit' ? '已审核' : '未审核') + '的数据')
      }
    },
    // 导出应收对账单
    // handleExport() {
    //   exportCmsChargeReport(this.queryParam).then(res => {
    //     if (res !== null) {
    //       const fileInfo = {
    //         fileName: res
    //       }
    //       downloadExcel(fileInfo).then(downRes => {
    //         const data = downRes.data
    //         let fileName = ''
    //         const contentDisposition = downRes.headers['content-disposition']
    //         if (contentDisposition) {
    //           fileName = decodeURI(escape(contentDisposition.split('=')[1]))
    //         }
    //         const url = window.URL.createObjectURL(
    //           new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
    //         )
    //         const link = document.createElement('a')
    //         link.style.display = 'none'
    //         link.href = url
    //         link.setAttribute('download', fileName)
    //         document.body.appendChild(link)
    //         link.click()
    //         document.body.removeChild(link)
    //       })
    //     }
    //   })
    // },
    // 导出业务来源统计
    handleCmsSourseExport(date) {
      const data = {
        export_type: date,
        receive_start_date: this.queryParam.receive_start_date,
        receive_end_date: this.queryParam.receive_end_date,
        invoice_start_time: this.queryParam.invoice_start_time,
        invoice_end_time: this.queryParam.invoice_end_time
      }
      exportCmsSource(data).then(res => {
        if (res !== null) {
          const fileInfo = {
            fileName: res
          }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data
            let fileName = ''
            const contentDisposition = downRes.headers['content-disposition']
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split('=')[1]))
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            )
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
        }
      })
    },
    handleBillApply() {
      this.clearForm()
      this.checkedList = []
      const feeMaps = []
      let total = 0
      this.selectedRows.forEach(row => {
        let feeList = []
        if (row.cms_fee_list) {
          feeList = feeList.concat(row.cms_fee_list)
        }
        if (row.wms_fee_list) {
          feeList = feeList.concat(row.wms_fee_list)
        }
        const orderFee = {
          planId: row.plan_id,
          feeList: feeList.filter(e => (e.fee_status === 2 || e.fee_status === 4) && e.charge_type === 2).map(e => {
            total += e.amount ? e.amount : 0
            this.checkedList.push(e.id)
            return {
              id: e.id,
              feeName: e.fee_name,
              money: e.amount
            }
          })
        }
        feeMaps.push(orderFee)
      })
      if (this.checkedList.length > 0) {
        this.bankAccountOps = []
        this.getCustomerOps(this.selectedRows[0].customs_name, this.selectedRows[0].customs_company)
        this.bizAuditForm = {
          ...this.bizAuditForm,
          customer: this.selectedRows[0].customs_name,
          fees: feeMaps,
          total: total
        }
        this.totalAmount = total
        this.applyFormVisible = true
      } else {
        this.$message.warning('没有可提交的费用,费用状态必须为业务已审核或商务审核被驳回')
      }
    },
    getCustomerOps(name, id) {
      getCustomerInfo({
        department: null,
        customerName: name,
        customerId: id
      }).then(res => {
        res.forEach(data => {
          this.customerInfo2 = {
            key: typeof data.value !== 'undefined' ? data.value : '',
            label: typeof data.name !== 'undefined' ? data.name : ''
          }
          this.form.customer_id = typeof data.value !== 'undefined' ? data.value : ''
          this.form.customer_name = typeof data.name !== 'undefined' ? data.name : ''
          this.form.tax_no = typeof data.taxNum !== 'undefined' ? data.taxNum : ''
          this.form.address = typeof data.street !== 'undefined' ? data.street : ''
          this.form.mobile = typeof data.phone !== 'undefined' ? data.phone : ''
          if (typeof data.bankAccount !== 'undefined') {
            this.bankAccountOps = data.bankAccount
            this.form.bank_card_no =
              typeof data.bankAccount[0].account !== 'undefined' ? data.bankAccount[0].account : ''
            this.form.bank = typeof data.bankAccount[0].bank_name !== 'undefined' ? data.bankAccount[0].bank_name : ''
          }
        })
      })
    },
    bankAccountChange(data) {
      this.bankAccountOps.forEach(item => {
        if (item.account === data) {
          this.applyInvoiceForm.bank = item.bank_name
        }
      })
    },
    handleApplyFormSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.confirmLoading = true
          const data = {
            ...this.form,
            id_list: this.checkedList
          }
          wmsFinanceChargeFunc('apply_finance_invoice', data).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '操作成功'
            })
            this.$refs.table.clearSelected()
            this.selectedRows = []
            this.applyFormVisible = false
            this.confirmLoading = false
            this.$refs.table.refresh(true)
          }).finally(_ => {
            this.confirmLoading = false
          })
        }
      })
    },
    feeStatusColor(value) {
      switch (value) {
        case 1:
          return '#faad14'
        case 2:
          return '#1890ff'
        case 3:
          return '#4cd964'
        case 4:
          return '#ff4d4f'
        default:
          return '#4cd964'
      }
    },
    feeOnChange(checkedValues) {
      let count = 0
      this.totalAmount = 0
      for (const item of this.bizAuditForm.fees) {
        count += item.feeList.length
        for (let i = 0; i < checkedValues.length; i++) {
          for (let j = 0; j < item.feeList.length; j++) {
            if (checkedValues[i] === item.feeList[j].id) {
              this.totalAmount += item.feeList[j].money
            }
          }
        }
      }
      this.indeterminate = !!this.checkedList.length && this.checkedList.length < count
      this.checkAll = this.checkedList.length === count
    },
    onCheckAllChange(e) {
      this.checkAll = e.target.checked
      this.indeterminate = false
      this.checkedList = []
      this.totalAmount = 0
      if (e.target.checked) {
        this.bizAuditForm.fees.forEach(item => {
          for (const e of item.feeList) {
            this.totalAmount += e.money
            this.checkedList.push(e.id)
          }
        })
      }
    },
    handleGenerateBill() {
      generateWmsBill({
        order_id_list: this.selectedRowKeys,
        info: this.exportDate.format('YYYY年MM月')
      }).then(res => {
        if (res !== null) {
          const fileInfo = {
            fileName: res
          }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data
            let fileName = ''
            const contentDisposition = downRes.headers['content-disposition']
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split('=')[1]))
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            )
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
        }
      }).finally(_ => {
        this.exportDate = undefined
        this.popVisible = false
      })
    },
    clearForm() {
      this.form = {
        apply_remark: '',
        customer_name: '',
        tax_no: '',
        address: '',
        mobile: '',
        bank: '',
        bank_card_no: '',
        remark: '',
        tax_rate: undefined
      }
      this.bizAuditForm = {
        customer: '',
        department: '查验部',
        creator: '',
        applyTime: moment().format('L'),
        fees: null,
        total: null
      }
    },
    // 导出POS机发票解款清单
    wmsPosInvoiceSettlementSheet() {
      exportWmsPosInvoiceSettlementSheet({
        exportStartDate: this.exportStartDate,
        exportEndDate: this.exportEndDate
      }).then(res => {
        if (res !== null) {
          const fileInfo = { fileName: res }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data
            let fileName = ''
            const contentDisposition = downRes.headers['content-disposition']
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split('=')[1]))
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            )
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
        }
      }).finally(() => {
        this.exportPopVisible = false;
      })
    },
    // 导出通联收入汇总表
    payIncomeTotalSheet() {
      exportPayIncomeTotalSheet({
        exportStartDate: this.exportStartDate,
        exportEndDate: this.exportEndDate
      }).then(res => {
        if (res !== null) {
          const fileInfo = { fileName: res }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data
            let fileName = ''
            const contentDisposition = downRes.headers['content-disposition']
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split('=')[1]))
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            )
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
        }
      }).finally(() => {
        this.exportPopVisible2 = false;
      })
    },
    wmsChargeTotalSheet() {
      exportChargeTotalSheet({
        exportStartDate: this.exportStartDate,
        exportEndDate: this.exportEndDate
      }).then(res => {
        if (res !== null) {
          const fileInfo = { fileName: res }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data
            let fileName = ''
            const contentDisposition = downRes.headers['content-disposition']
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split('=')[1]))
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            )
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
        }
      }).finally(() => {
        this.exportPopVisible3 = false;
      })
    },
    onDateRangeChange(date, dateString) {
      if (date[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss');
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss');
        this.exportStartDate = startDate
        this.exportEndDate = endDate
      } else {
        this.exportStartDate = null
        this.exportEndDate = null
      }
    }
  }
}
</script>

<style>
.color-info {
  color: #1890ff;
}
.color-error {
  color: #ff4d4f;
}
.color-success {
  color: #4cd964;
}
.color-warn {
  color: #faad14;
}
.color-active {
  color: #A940FF;
}
.color-disabled {
  color: #DADADA;
}
</style>
